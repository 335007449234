import { GenderType } from '@dadok/db';

declare type Locale = string;

interface Meta {
  URL: string | URL;
  siteName: string;
  title: string;
  description?: string;
  backgroundColor?: string;
  googleSiteVerification: string;
  og: {
    locale?: Locale;
    type?: 'website';
    ogImage: string | URL;
    width?: number;
    height?: number;
  };
}

// Nullish coalescing: the `??` operator is a way to “fall back” to
// a default value when dealing with `null` or `undefined`.

// Best approach: create a config file in your project where you export an
// object containing all configuration values for your project. There, you
// can set default values for variables coming from the environment. In this way,
// you guarantee a value will be present for the execution of your program:

export const ENV = {
  NEXT_PUBLIC_API_URL:
    process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000',
  NEXTAUTH_URL: process.env.NEXTAUTH_URL || 'http://localhost:3000',
  NEXT_PUBLIC_HOMEPAGE_URL:
    process.env.NEXT_PUBLIC_HOMEPAGE_URL || 'http://localhost:3000',
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID || '',
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET || '',
  KAKAO_CLIENT_ID: process.env.KAKAO_CLIENT_ID || '',
  KAKAO_CLIENT_SECRET: process.env.KAKAO_CLIENT_SECRET || '',
  NEXT_PUBLIC_KAKAO_API_KEY: process.env.NEXT_PUBLIC_KAKAO_API_KEY || '',
  NAVER_CLIENT_ID: process.env.NAVER_CLIENT_ID || '',
  NAVER_CLIENT_SECRET: process.env.NAVER_CLIENT_SECRET || '',
  APPLE_ID: process.env.APPLE_ID || '',
  APPLE_TEAM_ID: process.env.APPLE_TEAM_ID || '',
  APPLE_KEY_ID: process.env.APPLE_KEY_ID || '',
  APPLE_PRIVATE_KEY: process.env.APPLE_PRIVATE_KEY || '',
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET || '',
  GMAIL_EMAIL_ADDRESS: process.env.GMAIL_EMAIL_ADDRESS || '',
  GMAIL_APP_PASSWORD: process.env.GMAIL_APP_PASSWORD || '',
  SENDER_EMAIL_ADDRESS: process.env.SENDER_EMAIL_ADDRESS || '',
  GOOGLE_APPLICATION_CREDENTIALS:
    process.env.GOOGLE_APPLICATION_CREDENTIALS || '',
  GCS_PUBLIC_BUCKET_URL: `https://storage.googleapis.com/${process.env.GCS_PUBLIC_STORAGE || 'danang-public'}`,
  GCS_PUBLIC_STORAGE: process.env.GCS_PUBLIC_STORAGE || 'danang-public',
  GCS_PRIVATE_STORAGE: process.env.GCS_PRIVATE_STORAGE || 'dadok-care',
  SMS_KEY: process.env.SMS_KEY || '',
  SMS_SECRET: process.env.SMS_SECRET || '',
  SMS_FROM: process.env.SMS_FROM || '',
  IS_DEVELOPMENT:
    process.env.NODE_ENV === 'development' && process.env.ENV_NAME === 'dev',
  GRPC_VOICE_ANALYSIS_ADDRESS:
    process.env.GRPC_VOICE_ANALYSIS_ADDRESS || 'localhost:9009',
  GRPC_OVERALL_SCORE_ADDRESS:
    process.env.GRPC_OVERALL_SCORE_ADDRESS || 'ml-service-vm.baikal.ai:8585',
  GRPC_READING_TEXT_CHUNKER:
    process.env.GRPC_READING_TEXT_CHUNKER || 'ml-service-vm.baikal.ai:8161',
  TEST_ACCOUNT: process.env.TEST_ACCOUNT || '01001224525',
  FIREBASE_CREDENTIALS: process.env.FIREBASE_CREDENTIALS || 'firebase.json',
  STT_SOCKET_URL:
    process.env.STT_SOCKET_URL ||
    'https://stt-server-826031283816.asia-northeast3.run.app',
  NEXT_PUBLIC_WEBHOOK_GOOGLE_CHAT:
    process.env.NEXT_PUBLIC_WEBHOOK_GOOGLE_CHAT || '',
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || '',
  NEXT_PUBLIC_FIREBASE_PROJECT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || '',
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '',
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '',
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || '',
  DEFAULT_DANANG_NAME: process.env.DEFAULT_DANANG_NAME || '다낭'
};

export const ROUTES = {
  ADD: 'new',
  ROOT: '/',
  ERROR: '/info',
  NEW_USER: '/new-user',
  START: '/start',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  LOGOUT: '/logout',
  INVITE: '/invite',
  PRIVACY: 'privacy',
  REGISTER_API: '/api/register',
  FORGOT_PASSWORD_API: '/api/forgot-password',
  HOME: '/home',
  FAMILY_MANAGE: '/family/manage',
  FAMILY_MANAGE_ADD: '/family/manage/add',
  FAMILY_MANAGE_ADD_CONFIRM: '/family/manage/confirm-add',
  FAMILY_MISSION: '/family/mission',
  FAMILY_LOG: '/family/log',
  FAMILY_STATISTICS: '/family/statistics',
  DECLAMATION: '/declamation',
  DECLAMATION_LIFE_QUOTES: '/declamation/life-quotes',
  DECLAMATION_RESULT: '/declamation/result',
  STATISTICS: '/statistics',
  MORE: '/more',
  MANAGE_USER: '/more/manage-user',
  USERINFO: '/more/manage-user/user',
  REWARD: '/more/reward',
  NOTIFICATIONSETTINGS: '/more/notification-settings',
  NOTICE: '/more/notice',
  FAQ: '/more/faq',
  READDINGEFFECT: '/more/reading-effect',
  CUSTOMER_SERVICE: '/more/customer-service',
  CUSTOMER_SERVICE_ADD: '/more/customer-service/add',
  TERMS: '/more/terms',
  APP_VERSION: '/more/app-version',
  SOCIAL: '/more/manage-user/social',
  ADDRESS: '/more/manage-user/address',
  THEME: '/more/theme',
  VOICE_API: '/api/voice',
  DENY_BACK: '/deny-back',
  SUPPORT_TRIAL: '/trial',
  SUPPORT_TRIAL_RESULT: '/trial/result',
  API_SUPPORT_TRIAL: '/api/trial',
  ARTICLE_PACKAGE: '/article-package',
  ARTICLE: '/article',
  RECORDER: 'recorder',
  SEARCH: '/search',
  SEARCH_RESULT: '/search/result',
  MY: '/my',
  WISH_LIST: '/my/wish-list',
  RECENT_LIST: '/my/recent-list',
  ATTENDANCE: '/my/attendance',
  RECENT: '/recent',
  TROPHY: '/my/trophy',
  READING_TIME_SCORE: '/reading-time-score',
  ORGANIZATION: '/organization',
  COURSE: 'course',
  READING_LOG_API: '/api/readings/reading-log'
};

// Our basic SEO data
export const meta: Meta = {
  URL: ENV.NEXT_PUBLIC_API_URL,
  siteName: '다낭',
  title: '다낭',
  description: '다낭 웹뷰',
  backgroundColor: '#F5E1E6',
  og: {
    locale: 'ko-KR',
    type: 'website',
    ogImage: '/static/images/ogimage.jpg',
    width: 1200,
    height: 630
  },
  googleSiteVerification: 'kVo-pc5aOv11zyY543EtJmRCP-zLTBc8foCYCgX5kW4'
};

export const PATH = {
  UPLOAD: '/uploads',
  GCS_ARTICLE_VOICE: 'article-voice'
};

export const COOKIE_KEY = {
  USER_ID_FOR_IOS_OAUTH: 'danang-ios-oauth-id'
};

export const AUTH_CODE_TIMEOUT = 3;

export const TEXTS = {
  UNKOWN: '알 수 없음',
  NO_VALUE: '없음',
  NO_NAME: '회원'
};

export const MY_RECENT_TAB = {
  ARTICLE: 'article',
  ARTICLE_PACKAGE: 'article_package'
};

export const GenderTypeMap = new Map<string, string>([
  [GenderType.MALE, '남성'],
  [GenderType.FEMALE, '여성'],
  [GenderType.UNKNOWN, '선택 안 함']
]);

export const GenderTypeItems = Array.from(GenderTypeMap, ([id, text]) => ({
  value: id,
  label: text
}));
