import { Icons } from '#/components/icons';
import { ROUTES } from './constants';

export interface MenuItem {
  imgURL?: any;
  imgURLActive?: any;
  href: string;
  label: string;
  active: boolean;
  children?: MenuItem[];
  popover?: boolean;
  isMenu?: boolean;
}

export const MenuItems = (params: any, pathname: string): MenuItem[] => [
  {
    imgURL: Icons.inactiveBook,
    imgURLActive: Icons.bookTextActive,
    href: `/${params?.orgName}${ROUTES.HOME}`,
    label: '낭독',
    active: pathname.includes(`/${params?.orgName}${ROUTES.HOME}`),
    children: HOME_ROUTES(params, pathname)
  },
  {
    imgURL: Icons.flag,
    imgURLActive: Icons.activeFlag,
    href: `/${params.orgName}/${ROUTES.COURSE}`,
    label: '코스',
    active: pathname.includes(`/${params.orgName}/${ROUTES.COURSE}`)
  },
  {
    imgURL: Icons.search,
    imgURLActive: Icons.searchActive,
    href: `/${params.orgName}${ROUTES.SEARCH}`,
    label: '검색',
    active: pathname.includes(`/${params.orgName}${ROUTES.SEARCH}`)
  },
  {
    imgURL: Icons.my,
    imgURLActive: Icons.myActive,
    href: `/${params.orgName}${ROUTES.MY}`,
    label: 'MY',
    active: pathname.includes(`/${params.orgName}${ROUTES.MY}`)
  },
  {
    imgURL: Icons.list,
    imgURLActive: Icons.listActive,
    href: `/${params.orgName}${ROUTES.MORE}`,
    label: '더보기',
    active: pathname.includes(`/${params.orgName}${ROUTES.MORE}`),
    children: MORE_ROUTES(params, pathname)
  }
];

export const HOME_ROUTES = (params: any, pathname: string) => [
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_MANAGE}`,
    label: '가족 관리',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAMILY_MANAGE}`)
  },
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_MANAGE_ADD}`,
    label: '가족 연결하기',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAMILY_MANAGE_ADD}`)
  },
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_MANAGE_ADD_CONFIRM}`,
    label: '가족 초대 확인',
    active: pathname.includes(
      `/${params?.orgName}${ROUTES.FAMILY_MANAGE_ADD_CONFIRM}`
    )
  },
  {
    href: `/${params?.orgName}${ROUTES.INVITE}`,
    label: '초대 수락하기',
    active: pathname.includes(`/${params?.orgName}${ROUTES.INVITE}`)
  },
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_MISSION}`,
    label: '우리가족 활동미션',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAMILY_MISSION}`)
  },
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_LOG}`,
    label: '우리가족 낭독자료',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAMILY_LOG}`)
  },
  {
    href: `/${params?.orgName}${ROUTES.FAMILY_STATISTICS}`,
    label: '활동 통계',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAMILY_STATISTICS}`)
  }
];

export const RECORDER_ROUTES = (params: any, pathname: string) => [
  {
    href: `/${params?.orgName}${ROUTES.RECORDER}`,
    label: '내 책 낭독하기',
    active: pathname.includes(`/${params?.orgName}${ROUTES.RECORDER}`),
    isMenu: true
  },
  {
    href: `/${params?.orgName}${ROUTES.DECLAMATION_LIFE_QUOTES}`,
    label: '낭독 100선',
    active: pathname.includes(
      `/${params?.orgName}${ROUTES.DECLAMATION_LIFE_QUOTES}`
    ),
    isMenu: true
  },
  {
    href: `/${params?.orgName}${ROUTES.DECLAMATION_RESULT}`,
    label: '낭독 결과',
    active: pathname.includes(`/${params?.orgName}${ROUTES.DECLAMATION_RESULT}`)
  }
];

export const MORE_SERVICE_ROUTES = (params: any, pathname: string) => [
  {
    imgURL: Icons.readingMenu,
    href: `/${params?.orgName}${ROUTES.READDINGEFFECT}`,
    label: '낭독 예찬',
    active: pathname.includes(`/${params?.orgName}${ROUTES.READDINGEFFECT}`)
  },
  {
    imgURL: Icons.noticeMenu,
    href: `/${params?.orgName}${ROUTES.NOTICE}`,
    label: '공지사항',
    active: pathname.includes(`/${params?.orgName}${ROUTES.NOTICE}`)
  }
  // {
  //   imgURL: Icons.notificationMenu,
  //   href: `/${params?.orgName}/${ROUTES.NOTIFICATIONSETTINGS}`,
  //   label: '알림',
  //   active: pathname.includes(`/${params?.orgName}/${ROUTES.NOTIFICATIONSETTINGS}`)
  // }
];

export const MORE_SETTING_ROUTES = (params: any, pathname: string) => [
  {
    imgURL: Icons.userMenu,
    href: `/${params?.orgName}${ROUTES.MANAGE_USER}`,
    label: '계정 관리',
    active: pathname.includes(`/${params?.orgName}${ROUTES.MANAGE_USER}`),
    children: USER_INFO_ROUTES(params, pathname)
  },
  {
    imgURL: Icons.settingMenu,
    href: `/${params?.orgName}${ROUTES.NOTIFICATIONSETTINGS}`,
    label: '서비스 이용 설정',
    active: pathname.includes(
      `/${params?.orgName}${ROUTES.NOTIFICATIONSETTINGS}`
    )
  },
  {
    imgURL: Icons.appVersionMenu,
    href: `/${params?.orgName}${ROUTES.APP_VERSION}`,
    label: '앱 버전',
    active: pathname.includes(`/${params?.orgName}${ROUTES.APP_VERSION}`)
  }
];

export const HELP_ROUTES = (params: any, pathname: string) => [
  {
    imgURL: Icons.faqMenu,
    href: `/${params?.orgName}${ROUTES.FAQ}`,
    label: '자주 묻는 질문',
    active: pathname.includes(`/${params?.orgName}${ROUTES.FAQ}`)
  },
  {
    imgURL: Icons.termsMenu,
    href: `/${params?.orgName}${ROUTES.TERMS}`,
    label: '약관 확인하기',
    active: pathname.includes(`/${params?.orgName}${ROUTES.TERMS}`)
  },
  {
    imgURL: Icons.helpMenu,
    href: `/${params?.orgName}${ROUTES.CUSTOMER_SERVICE}`,
    label: '고객센터',
    active: pathname.includes(`/${params?.orgName}${ROUTES.CUSTOMER_SERVICE}`)
  }
];

export const MORE_ROUTES = (params: any, pathname: string) => [
  ...MORE_SERVICE_ROUTES(params, pathname),
  ...MORE_SETTING_ROUTES(params, pathname),
  ...HELP_ROUTES(params, pathname)
];

export const USER_INFO_ROUTES = (params: any, pathname: string) => [
  {
    href: `/${params?.orgName}${ROUTES.SOCIAL}`,
    label: '소셜연동',
    active: pathname.includes(`/${params?.orgName}${ROUTES.SOCIAL}`)
  }
  //   {
  //     href: `/${params?.orgName}/${ROUTES.ADDRESS}`,
  //     label: '주소지 관리',
  //     active: pathname.includes(`/${params?.orgName}/${ROUTES.SOCIAL}`)
  //   }
];

export function convertMenuText(p: Map<string, string>, l: MenuItem) {
  p.set(l.href, l.label);
  l.children?.forEach((item) => {
    convertMenuText(p, item);
  });
  return p;
}

export const MENU_TEXT_MAP = MenuItems({}, '').reduce(
  convertMenuText,
  new Map()
);
