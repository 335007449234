export const LOG_EVENTS = {
  MY_ACTIVITY: 'my_activity',
  INTRO_MORE: 'intro_more',
  WORD_PLAY_MORE: 'word_play_more',
  INTIMATE_MORE: 'intimate_more',
  ARTICLE_PACKAGE_THUMBNAIL: 'article_package_thumbnail',
  MINI_PLAYER_REPLAY: 'mini_player_replay',
  ARTICLE_TAB: 'article_tab',
  INFORMATION_TAB: 'information_tab',
  ARTICLE_IN_PACKAGE: 'article_in_package',
  READING_BUTTON: 'reading_button',
  READING_PLAY: 'reading_play',
  READING_PAUSE: 'reading_pause',
  READING_REPLAY: 'reading_replay',
  READING_RESET: 'reading_reset',
  READING_FINISH: 'reading_finish',
  CHUNKS_OPTION: 'chunks_option',
  WORD_OPTION: 'word_option',
  ORIGIN_OPTION: 'origin_option',
  READING_INTRODUCE: 'reading_introduce',
  VIEW_SETTINGS: 'view_settings',
  TAG_SEARCH: 'tag_search',
  CATEGORY_SEARCH: 'category_search',
  AUTOCOMPLETE_SEARCH: 'autocomplete_search',
  SEARCH_QUERY: 'search_query',
  CATEGORY_SEARCH_FROM_RESULT: 'category_search_from_result',
  DELETE_QUERY: 'delete_query',
  MY_TAB_READING_TIME_VISIT: 'my_tab_reading_time_visit',
  RECENT: 'recent',
  MY_TAB_ATTENDANCE_VISIT: 'my_tab_attendance_visit',
  RECENT_LIST: 'recent_list',
  MY_TAB_TROPHY_VISIT: 'my_tab_trophy_visit',
  RECENT_MORE: 'recent_more',
  RECENT_ARTICLE_TAB: 'recent_article_tab',
  RECENT_ARTICLE_PACKAGE_TAB: 'recent_article_package_tab',
  RECENT_ARTICLE: 'recent_article',
  RECENT_ARTICLE_PACKAGE: 'recent_article_package',
  RECENT_REPLAY: 'recent_replay',
  WISH_MORE: 'wish_more',
  ADD_WISH: 'add_wish',
  REMOVE_WISH: 'remove_wish',
  NOT_FOUND_ARTICLE: 'not_found_article',
  NOT_FOUND_ARTICLE_PACKAGE: 'not_found_article_package',
  NOT_FOUND_WISH: 'not_found_wish',
  MAIN_BOTTOM: 'main_bottom',
  READING_EFFECT: 'reading_effect',
  NOTICE_PAGE_ENTRY: 'notice_page_entry',
  NOTICE_ITEM: 'notice_item',
  USER_PAGE_ENTRY: 'user_page_entry',
  USER_INFO: 'user_info',
  WITHDRAWAL: 'withdrawal',
  WITHDRAWAL_YES: 'withdrawal_yes',
  WITHDRAWAL_NO: 'withdrawal_no',
  LOGOUT: 'logout',
  NOTIFICATION_SETTINGS: 'notification_settings',
  MARKETING_AGREEMENT: 'marketing_agreement',
  MARKETING_AGREEMENT_WITHDRAWAL_YES: 'marketing_agreement_withdrawal_yes',
  MARKETING_AGREEMENT_WITHDRAWAL_NO: 'marketing_agreement_withdrawal_no',
  APP_VERSION_PAGE_ENTRY: 'app_version_page_entry',
  FAQ_PAGE_ENTRY: 'faq_page_entry',
  FAQ_ITEM: 'faq_item',
  TERMS: 'terms',
  TERMS_ITEM: 'terms_item',
  CUSTOMER_SERVICE_PAGE_ENTRY: 'customer_service_page_entry',
  FAQ_BUTTON: 'faq_button',
  CUSTOMER_SERVICE_ADD_PAGE_ENTRY: 'customer_service_add_page_entry',
  CUSTOMER_SERVICE_ACCEPTANCE: 'customer_service_acceptance',
  MY_RECENT_DATA_FETCHING_ERROR: 'my_recent_data_fetching_error',
  STOP_RECORDING_ERROR: 'stop_recording_error',
  STT_ERROR: 'stt_error',
  FETCH_ARTICLE_PACKAGE_ERROR: 'fetch_article_package_error',
  MOBILE_LOGIN: 'mobile_login',
  SEND_SMS: 'send_sms',
  CONFIRM_SMS: 'confirm_sms',
  SIGN_IN: 'sign_in',
  // NEW_USER: 'new_user',
  CHECK_TERMS_AGREEMENT: 'check_terms_agreement',
  TERMS_AGREEMENT_MORE_ITEM: 'terms_agreement_more_item',
  COMPLETE_NEW_SIGN_IN: 'complete_new_sign_in',
  NEW_USER_FIRST_DURATION: 'new_user_first_duration',
  NEW_USER_GENDER: 'new_user_gender',
  NEW_USER_AGE_GROUP: 'new_user_age_group',
  NEW_USER_PLATFORM: 'new_user_platform',
  HOME_PAGE_DURATION: 'home_page_duration',
  HOME_PAGE_SCROLL_DEPTH: 'home_page_scroll_depth',
  SEARCH_RESULT_PAGE_DURATION: 'search_result_page_duration',
  SEARCH_RESULT_PAGE_ENTRY: 'search_result_page_entry',
  SEARCH_RESULT_PAGE_SCROLL_DEPTH: 'search_result_page_scroll_depth',
  ARTICLE_PAGE_DURATION: 'article_page_duration',
  RECORDER_PAGE_DURATION: 'recorder_page_duration',
  ARTICLE_PAGE_BACK_AFTER_TIME: 'article_page_back_after_time',
  READING_ERROR: 'reading_error',
  SWIPER_MORE_CLICKED: 'swiper_more_clicked' //
};

export const BG_COLORS = {
  WHITE: 'white',
  NEUTRAL: 'neutral',
  GRAY: 'gray',
  DARK_GRAY: 'dark_gray',
  BLACK: 'black'
};

export function getScrollPercentage(percentage: number) {
  if (percentage >= 100) return '100%';
  if (percentage >= 75) return '75%';
  if (percentage >= 50) return '50%';
  if (percentage >= 25) return '25%';
  return '0%';
}

export function getSearchTime() {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 0 && hour < 6) {
    return '0_6';
  } else if (hour >= 6 && hour < 12) {
    return '6_12';
  } else if (hour >= 12 && hour < 18) {
    return '12_18';
  } else {
    return '18_24';
  }
}
