'use client';

import { LOG_EVENTS } from '#/data/event';
import { MenuItems } from '#/data/menu';
import { cn } from '#/lib/utils';
import { WebViewEvent, showWebToast } from '#/lib/webview';
import { logEvent } from '#/lib/analytics';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

function Bottombar() {
  const pathname = usePathname();
  const params = useParams();
  const routes = MenuItems(params, pathname || '');
  const router = useRouter();

  useEffect(() => {
    window.addEventListener(WebViewEvent.toastFromApp, showWebToast);

    return () => {
      window.removeEventListener(WebViewEvent.toastFromApp, showWebToast);
    };
  }, []);

  return (
    <>
      <section className="bottombar-height" />
      <section className="bottombar bottombar-height">
        <div className="bottombar_container">
          {routes.map((link) => {
            return (
              <div
                onClick={() => {
                  logEvent(LOG_EVENTS.MAIN_BOTTOM, {
                    route: link.href
                  });
                  router.push(link.href);
                  router.refresh();
                }}
                key={link.label}
                className={`bottombar_link no_highlights ${
                  link.active ? 'text-foreground' : 'text-muted-foreground'
                }`}
              >
                {link.active ? <link.imgURLActive /> : <link.imgURL />}

                <p
                  className={cn(
                    'text-xs text-subtle-medium',
                    link.active ? 'text-primary' : ''
                  )}
                >
                  {link.label}
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Bottombar;
