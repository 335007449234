'use client';

import { LOG_EVENTS } from '#/data/event';
import { useNewUserStore } from '#/hooks/use-new-user-store';
import { logEvent } from '#/lib/analytics';
import { isIOS } from '#/lib/webview';
import { useEffect, useRef } from 'react';

export interface ContentsWrapperProps {
  children: React.ReactNode;
}

export default function ContentsWrapper({ children }: ContentsWrapperProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const { isNewUser, gender, joinedAt, setIsNewUser } = useNewUserStore();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
        // 백그라운드로 이동 시 실행할 작업
        if (isNewUser) {
          setIsNewUser(false); // 첫 트리거에만 동작
          logEvent(LOG_EVENTS.NEW_USER_FIRST_DURATION, {
            duration: (Date.now() - joinedAt.getTime()) / 1000
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    const handleVisualViewPortResize = () => {
      const currentVisualViewport = Number(window.visualViewport?.height);
      if (divRef) {
        if (document.documentElement.clientHeight === currentVisualViewport) {
          divRef.current!.style.height = '';
        } else {
          divRef.current!.style.height = `${document.documentElement.clientHeight - currentVisualViewport + (divRef.current?.clientHeight ?? 0)}px`;
        }
      }
    };
    if (isIOS()) {
      if (window.visualViewport) {
        window.visualViewport.addEventListener(
          'resize',
          handleVisualViewPortResize
        );
      }
      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener(
            'resize',
            handleVisualViewPortResize
          );
        }
      };
    }
  }, [isNewUser, gender, joinedAt]);
  return (
    <div ref={divRef} className="bg-background ">
      {children}
    </div>
  );
}
