import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface NewUser {
  isNewUser: boolean;
  joinedAt: Date;
  gender: string;
  birthdate: Date | null;
  setNewUser: (gender: string, birthdate: Date | null, joinedAt: Date) => void;
  resetUser: () => void;
  setIsNewUser: (value: boolean) => void;
}

export const useNewUserStore = create(
  persist<NewUser>(
    (set) => ({
      isNewUser: false,
      joinedAt: new Date(),
      gender: 'unknown',
      birthdate: null,
      setNewUser: (gender, birthdate, joinedAt) =>
        set({ isNewUser: true, gender, birthdate, joinedAt }),
      resetUser: () => {
        set({
          isNewUser: false,
          joinedAt: new Date(),
          gender: 'unknown',
          birthdate: null
        });
      },
      setIsNewUser: (value: boolean) => set({ isNewUser: value })
    }),
    {
      name: 'newUserStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
