import { initializeApp } from 'firebase/app';
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent as FirebaselogEvent
} from 'firebase/analytics';
import { ENV } from '#/data/constants';

const firebaseConfig = {
  apiKey: ENV.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: ENV.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: ENV.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: ENV.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: ENV.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

let analytics: Analytics;
if (firebaseConfig.projectId) {
  const app = initializeApp(firebaseConfig);

  //정적 페이지 생성 시 오류를 해결
  if (app.name && typeof window !== 'undefined') {
    isSupported()
      .then((_) => {
        analytics = getAnalytics(app);
      })
      .catch((e) =>
        console.warn(
          'Analytics is not supported in this environment.',
          e.message
        )
      );
  }
}

const logEvent = (
  eventName: string,
  eventParams?: {
    [key: string]: any;
  }
) => {
  try {
    if (process.env.NODE_ENV === 'development') {
      console.log('logEvent: ', eventName, eventParams);
      return;
    } else {
      return FirebaselogEvent(analytics, eventName, eventParams);
    }
  } catch (error) {
    console.log(error);
  }
};

export { logEvent };
