import { ROUTES } from '#/data/constants';
import { useFetch } from '#/hooks';
import { VoiceType } from '@dadok/db';
import { ServerResponse } from 'types/common';

export function useVoiceService(): VoiceService {
  const client = useFetch();
  return {
    upload: async (
      nowFile,
      currentFile,
      voiceType,
      chunksList,
      readingChunksList,
      isMale,
      articleId,
      articlePackageId,
      isVerse,
      title,
      orgName,
      isCourse
    ) => {
      return client.upload('/api/recorderUpload', {
        nowFile: nowFile,
        currentFile: currentFile,
        voiceType,
        articleId: articleId,
        chunksList,
        readingChunksList,
        isMale,
        articlePackageId,
        isVerse,
        title,
        orgName,
        isCourse
      });
    },
    trial: async (blob, isMale) => {
      return client.upload(ROUTES.API_SUPPORT_TRIAL, {
        file: blob,
        isMale
      });
    },
    getTemporaryUrl: async (id) => await client.get(`${ROUTES.VOICE_API}/${id}`)
  };
}

interface VoiceService {
  upload: (
    nowFile: Blob,
    currentFile: Blob,
    voiceType: VoiceType,
    chunksList: string,
    readingChunksList: string,
    isMale: boolean,
    articleId: string,
    articlePackageId: string,
    isVerse: boolean,
    title: string,
    orgName: string,
    isCourse: boolean
  ) => Promise<ServerResponse>;
  trial: (blob: Blob, isMale: boolean) => Promise<any>;
  getTemporaryUrl: (id: string) => Promise<string>;
}
